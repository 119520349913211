$accent-color: #001640;
$accent-light: rgba(
  $color: $accent-color,
  $alpha: 0.15,
);
$spacing-xs: 1rem;
$spacing-m: 3.5rem;
$emphasis-rgb: 30, 144, 255;
$segments-marker-header-button-width: 1.5rem;
$border-radius-small: 5px;
$emphasis-color: dodgerblue;
$emphasis-extra-light: rgba(
  $color: $emphasis-color,
  $alpha: 0.05,
);
$editor-control-button-width: 1.5rem;
$warning-color: #ffc400;
$segment-warning-width: 30rem;
$segment-warning-font-size: 12px;
$editor-font-size: 14px;
$editor-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$segment-line-height: 1.4rem;
$transition-time: 0.2s;
$text-marker-horizontal-spacing: 4px;
$header-height: 4rem;

.Wizard-Editor-Section-wrapper {
  > .Wizard-Editor-Page-wrapper {
    border-color: $accent-light;
    .Wizard-Editor-Marker-Segments-wrapper {
      --content-padding: calc(#{$spacing-xs} / 2);
      $content-padding: var(--content-padding, 0px);
      $color: rgba(var(--color, $emphasis-rgb), 1);
      $color-light: rgba(var(--color, $emphasis-rgb), 0.15);
      $text-color: rgba(var(--text-color, #{0, 0, 0}), 1);
      > .Wizard-Editor-Marker-Segments-content {
        padding-left: $content-padding;
        padding-right: $content-padding;
        outline-color: $color;
        color: $text-color;
        background-color: $color-light;
        > .Wizard-Editor-Marker-Segments-header {
          height: $segments-marker-header-button-width;
        }
      }
      &[data-start='true'] {
        padding-top: $segments-marker-header-button-width;
        > .Wizard-Editor-Marker-Segments-content {
          padding-top: $content-padding;
          > .Wizard-Editor-Marker-Segments-header {
            > .Wizard-Editor-Marker-Segments-buttonGroup {
              --button-height: #{$segments-marker-header-button-width};
              --button-width: #{$segments-marker-header-button-width};
              border-color: $accent-light;
              border-radius: $border-radius-small;
              > .Wizard-Editor-Marker-MarkerAssignSelect-wrapper {
                width: $segments-marker-header-button-width;
              }
            }
          }
        }
      }
      &[data-end='true'] {
        padding-bottom: calc(#{$segments-marker-header-button-width} / 4);
        > .Wizard-Editor-Marker-Segments-content {
          padding-bottom: $content-padding;
        }
      }
    }
    .Wizard-Editor-Segment-wrapper {
      &[data-mode='edit']:hover {
        // > .Wizard-Editor-Segment-Paragraph-wrapper,
        // > .Wizard-Editor-Segment-Table-wrapper {
        background-color: $emphasis-extra-light;
        // }
      }
      > .Wizard-Editor-Segment-warning {
        width: $editor-control-button-width;
        height: $editor-control-button-width;
        margin-left: -$spacing-xs;
        top: calc(100% + #{$border-radius-small});
        border-radius: $border-radius-small;
        background-color: $warning-color;
        &:hover {
          padding: calc(#{$spacing-xs} / 2);
          width: $segment-warning-width;
        }
        > .SVG-Icon {
          width: $editor-control-button-width;
          height: $editor-control-button-width;
        }
        > pre {
          margin-left: calc(#{$spacing-xs} / 2);
          line-height: $segment-warning-font-size;
          font-family: $editor-font-family;
          font-size: $segment-warning-font-size;
        }
      }
    }
    .Wizard-Editor-Segment-Paragraph-wrapper {
      min-height: $segment-line-height;
      line-height: $segment-line-height;
      font-family: $editor-font-family;
      //font-size: $editor-font-size;
      // border-radius: $border-radius-small;
      transition: transform $transition-time;
      > .Wizard-Editor-Segment-Paragraph-controlsWrapper {
        --button-height: $editor-control-button-width;
        // height: $editor-control-button-width;
        padding-left: $spacing-xs;
        > .Wizard-Editor-Segment-Paragraph-actionSelect {
          height: $editor-control-button-width;
          > .Select-optionsDiv {
            height: calc(#{$editor-control-button-width} + 2px);
            top: -2px;
            bottom: -2px;
            border-color: $accent-light;
            border-radius: $border-radius-small;
            transition: opacity $transition-time;
            filter: drop-shadow(0px 2px 3px $accent-light);
            > .Wizard-Editor-Segment-Paragraph-actionButtonGroup {
              margin: 0 calc(#{$spacing-xs} / 4);
              padding: 0 calc(#{$spacing-xs} / 4);
              border-color: $accent-light;
            }
            > .Wizard-Editor-Segment-Paragraph-customStyleSelect {
              height: $editor-control-button-width;
            }
            .Wizard-Editor-Segment-Paragraph-actionButton {
              width: $editor-control-button-width;
              height: $editor-control-button-width;
            }
          }
        }
        // > .Wizard-Editor-Segment-Paragraph-controls {
        //   padding: calc(#{$spacing-xs} / 4);
        //   border-color: $accent-light;
        //   > .Wizard-Editor-Segment-Paragraph-controlButton {
        //     width: $editor-control-button-width;
        //     height: $editor-control-button-width;
        //     padding: calc(#{$spacing-xs} / 8);
        //   }
        // }
      }
      .Wizard-Editor-Marker-Text-wrapper {
        $color: rgba(var(--color, $emphasis-rgb), 1);
        $color-light: rgba(var(--color, $emphasis-rgb), 1);
        $text-color: rgba(var(--text-color, #{0, 0, 0}), 1);
        margin: -1px calc(#{$text-marker-horizontal-spacing} - 1px);
        color: $text-color;
        border-color: $color;
        background-color: $color-light;
        scroll-margin-top: calc(#{$spacing-m} * 2 + #{$header-height});
        &:hover {
          background-color: $color;
        }
        > div {
          &[data-position='leading'] > div > .Wizard-Editor-Marker-Text-buttonGroup {
            padding-right: $border-radius-small;
            padding-left: unset;
          }
          &[data-position='trailing'] > div > .Wizard-Editor-Marker-Text-buttonGroup {
            padding-left: $border-radius-small;
            padding-right: unset;
          }
          > div {
            > .Wizard-Editor-Marker-Text-buttonGroup {
              background-color: $color;
              > .Wizard-Editor-Marker-MarkerAssignSelect-wrapper {
                &:hover {
                  > span > .SVG-Icon {
                    color: $color !important;
                  }
                }
                > .Select-optionsDiv {
                  border-color: $color;
                  > .Select-option {
                    &:hover {
                      background-color: $color;
                    }

                    + .Wizard-Editor-Marker-MarkerAssignSelect-optionGroupLabel {
                      border-color: $color-light;
                    }
                  }
                }
              }
              > .Wizard-Editor-Marker-Text-button {
                &:hover {
                  color: $color !important;
                }
              }
            }
          }
        }
      }
    }
    .Wizard-Editor-Segment-Paragraph-textarea {
      margin: calc(-#{$spacing-xs} / 4) -$spacing-xs;
      > .Input-textarea {
        padding: calc(#{$spacing-xs} / 4) $spacing-xs;
        width: calc(100% + 2 * #{$spacing-xs});
        min-height: $segment-line-height;
        line-height: $segment-line-height;
        font-family: $editor-font-family;
        font-size: $editor-font-size;
        border-radius: $border-radius-small;
        border-color: $accent-light;
      }
      > .Input-buttonGroup {
        > .Input-confirmButton,
        > .Input-discardButton {
          border-color: $accent-color;
        }
      }
    }
  }
}
