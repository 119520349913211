.Wizard-Editor-Segment-Paragraph-wrapper {
  --page-margin-left: 0px;
  --page-margin-right: 0px;
  $page-margin-left: var(--page-margin-left, 0px);
  $page-margin-right: var(--page-margin-right, 0px);
  $indent: var(--indent, 0px);
  $left: calc(#{$page-margin-left} + #{$indent});
  $right: $page-margin-right;

  position: relative;

  width: calc(100% - #{$left} - #{$right});
  margin: 0;
  margin-left: $left;
  margin-right: $right;

  overflow-wrap: break-word;
  user-select: text;
  white-space: pre-line;

  &:hover {
    > .Wizard-Editor-Segment-Paragraph-controlsWrapper {
      display: flex;
    }
  }
  > .Wizard-Editor-Segment-Paragraph-controlsWrapper {
    position: absolute;
    display: none;

    justify-content: flex-end;
    align-items: flex-start;

    height: 100%;

    top: 0;
    left: 100%;

    z-index: 10;

    ::selection {
      color: inherit;
      background-color: inherit;
    }

    > .Wizard-Editor-Segment-Paragraph-actionSelect {
      flex-shrink: 0;
      padding: 0;
      border-color: rgba(255, 255, 255, 0);
      background-color: rgba(255, 255, 255, 0);
      > .Select-optionsDiv {
        overflow: visible;
        background-color: white;
        > .Wizard-Editor-Segment-Paragraph-actionButtonGroup {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-left: 1px solid;
          border-right: 1px solid;
          ~ .Wizard-Editor-Segment-Paragraph-actionButtonGroup {
            margin-right: 0 !important;
            padding-right: 0 !important;
            border-right: none;
          }
        }
        > .Wizard-Editor-Segment-Paragraph-customStyleSelect {
          border: none;
          > .Select-optionsDiv {
            max-height: 10rem;
            overflow-y: auto;
          }
        }
        .Wizard-Editor-Segment-Paragraph-actionButton {
          min-width: unset;
          min-height: unset;
          padding: 0;
          border-radius: 0;
        }
      }
    }
  }
}
