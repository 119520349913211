.Wizard-Editor-Marker-Text-wrapper {
  position: relative;
  display: inline;
  padding: 1px 0px;
  border: 1px dashed;
  user-select: text;
  --color: 30, 144, 255;
  --text-color: 255, 255, 255;
  // &[data-availability='available'] {
  //   --color: 32, 224, 64;
  // }
  // &[data-availability='unavailable'] {
  //   --color: 255, 92, 64;
  // }
  cursor: pointer;
  &[data-color-variant='variant-1'] {
    --color: var(--marker-color-variant-1);
    --text-color: 0, 0, 0;
  }
  &[data-color-variant='variant-2'] {
    --color: var(--marker-color-variant-2);
    --text-color: 0, 0, 0;
  }
  &[data-color-variant='variant-3'] {
    --color: var(--marker-color-variant-3);
    --text-color: 0, 0, 0;
  }
  &[data-color-variant='variant-4'] {
    --color: var(--marker-color-variant-4);
    --text-color: 255, 255, 255;
  }
  &[data-mode='preview'] {
    // --color: 128, 128, 255;
    // --color: 30, 144, 255;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0) !important;
  }
  &[data-remove='true'] {
    color: red !important;
    opacity: 0.5;
    background-color: rgba(255, 0, 0, 0.1) !important;
    border: 1px dotted red !important;
  }
  &:hover {
    color: white;
    z-index: 10;
    > div {
      display: inline;
      z-index: 1;
      &[data-position='leading'] {
        z-index: 2;
      }
    }
    > span {
      color: white;
    }
  }
  > div {
    position: relative;
    display: none;
    &[data-position='leading'] > div {
      justify-content: flex-end;
      left: unset;
      right: 100%;
    }
    &[data-position='trailing'] > div {
      justify-content: flex-start;
      left: 100%;
      right: unset;
    }
    > div {
      position: absolute;
      display: flex;
      align-items: center;
      height: 1rem;
      top: 0;
      > .Wizard-Editor-Marker-Text-buttonGroup {
        height: calc(1rem + 4px);
        border: none;
        border-radius: 0 !important;
        filter: none;
        > .Wizard-Editor-Marker-MarkerAssignSelect-wrapper {
          height: 100%;
          min-width: 0;
          min-height: 0;
          aspect-ratio: 1;
          padding: 2px;
          color: white;
          background-color: rgba(255, 255, 255, 0);
          border-radius: 0 !important;
          &:hover {
            background-color: white !important;
          }
          > span {
            width: 100%;
            height: 100%;
            .SVG-Icon {
              color: white;
            }
          }
          .Select-option {
            border: none;
            + .Wizard-Editor-Marker-MarkerAssignSelect-optionGroupLabel {
              border-top: 1px solid;
            }
          }
          .Wizard-Editor-Marker-MarkerAssignSelect-optionGroupLabel {
            padding: 0 0.5rem;
            font-style: italic;
            color: gray;
            background-color: white;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            user-select: none;
            cursor: auto;
          }
        }
        > .Wizard-Editor-Marker-Text-button {
          min-width: 0;
          min-height: 0;
          aspect-ratio: 1;
          padding: 2px;
          color: white;
          border-radius: 0 !important;
          &:hover {
            background-color: white !important;
          }
        }
      }
    }
  }
  > .Wizard-Editor-Marker-Text-wrapper {
    --color: 255, 128, 0;
    > .Wizard-Editor-Marker-Text-wrapper {
      --color: 64, 196, 64;
      > .Wizard-Editor-Marker-Text-wrapper {
        --color: 64, 96, 128;
      }
    }
  }
}
